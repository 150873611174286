'use client';

import { createContext, useContext } from 'react';

import { iProviderProps } from '@/app/_providers/Providers';

interface iPrivateCloudContext {
  host?: string;
  isInContext: boolean;
}

const PrivateCloudContext = createContext<iPrivateCloudContext>({
  isInContext: false,
});

export const usePrivateCloudContext = () => useContext(PrivateCloudContext);

interface ServerProps {
  privateCloudHost: string;
}

export const PrivateCloudProvider = ({
  children,
  serverProps: { privateCloudHost },
}: iProviderProps<ServerProps>) => {
  return (
    <PrivateCloudContext.Provider
      value={{ host: privateCloudHost, isInContext: true }}
    >
      {children}
    </PrivateCloudContext.Provider>
  );
};
